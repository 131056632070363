import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Map from '../components/map'
import Container from '../components/container';
import * as styles from '../styles/ueberMich.module.scss'


const IndexPage = () => (

    <StaticQuery
        query={graphql`
        query {
            portrait: file(relativePath: { eq: "portrait_square.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        `}
        render={data => (
            <Layout>
                <SEO title='Über mich' keywords={[`gatsby`, `application`, `react`]} />

                <Container className={styles.container}>
                    <div className={styles.portraitContainer} >
                        <Img fluid={data.portrait.childImageSharp.fluid}
                            objectFit="cover"
                            imgStyle={{ clipPath: "circle(50% at center)" }} />
                    </div>

                    <h1>Über mich</h1>

                    <p className={styles.introText}> Mein Name ist Anne Walter Hänni.
                      Nach über 20-jähriger Tätigkeit als Ergotherapeutin mit Kindern und Jugendlichen,
                      habe ich 2019 den Schritt in die Selbständigkeit gewagt und die Praxis «Ergotherapie für Kinder»
                      von Cécile Frehner (Russikon) übernommen.</p>

                    <h2>Berufserfahrung</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.yearCell}>1993</td>
                                <td>Diplom für Ergotherapie an der Schule für Ergotherapie Zürich</td>
                            </tr>
                            <tr>
                                <td className={styles.yearCell}>1996 – 2003</td>
                                <td>Heilpädagogische Schule der RGZ-Stiftung, 8157 Dielsdorf</td>
                            </tr>
                            <tr>
                                <td className={styles.yearCell}>2003 – 2019</td>
                                <td>Sonderschule Ilgenhalde, 8320 Fehraltorf</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Weiterbildungen</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.yearCell}>1998 - 1999</td>
                                <td>Entwicklungsneurologische Therapie nach Bobath (NDT)</td>
                            </tr>
                            <tr>
                                <td className={styles.yearCell}>2001</td>
                                <td>Sensorische Integrationstherapie nach J. Ayres Grundkurs und Aufbaukurse</td>
                            </tr>
                            <tr>
                                <td className={styles.yearCell}>2006 - 2007</td>
                                <td>Castillo Morales Konzept Grundkurs</td>
                            </tr>
                            <tr>
                                <td className={styles.yearCell}>2018</td>
                                <td>Bobath – Refresher</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Viele weitere ein- und zweitätige Weiterbildungen</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Sprachen</h2>
                    <ul className={styles.languageList}>
                        <li>🇩🇪 Deutsch</li>
                        <li>🇫🇷 Französisch</li>
                        <li>🇬🇧 Englisch</li>
                    </ul>
                </Container>
                <Map />
            </Layout >
        )} />
)


export default IndexPage